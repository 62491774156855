<template>
  <label v-if="preLabel" :for="id" class="inline-block mr-5 cursor-pointer">{{
    preLabel
  }}</label>
  <input
    :id="id"
    type="checkbox"
    role="switch"
    class="sr-only"
    :aria-checked="modelValue"
    :checked="modelValue"
    :disabled="disabled"
    @change="update"
  />
  <label
    :for="id"
    class="toggle-switch"
    :class="{
      'is-on-dark': isOnDark,
      'is-reversed': isReversed === undefined ? true : isReversed,
    }"
  >
    <slot />
  </label>
</template>

<script lang="ts" setup>
defineProps<{
  id: string
  isOnDark?: boolean
  disabled?: boolean
  modelValue: boolean
  isReversed?: boolean
  preLabel?: string
}>()

const emit = defineEmits(['update:modelValue'])

const update = (e: Event) => {
  if (e.target && e.target instanceof HTMLInputElement) {
    emit('update:modelValue', e.target.checked)
  }
}
</script>

<style lang="postcss">
@import '~/assets/css/components/form-elements/toggle-switch.css';
</style>
